
import Vue from 'vue';

export default Vue.extend({
  name: 'EmptyScheduledExport',
  components: {},
  props: ['exportData', 'exportContext'],
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$emit('rendered', { empty: false });
      }, 10);
    });
  },
});
