
import Vue from 'vue';

export default Vue.extend({
  name: 'export-debug',
  components: {},
  props: ['exportData', 'exportContext'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: (): any => ({
    errorMessage: null,
    debugMessage: null,
  }),
  computed: {},
  mounted(): void {
    if (!this.exportData) {
      this.errorMessage = 'no data';
      return;
    }
    this.debugMessage =
      `<b>user</b>: ${this.exportData.user.email}<br/>` +
      `<b>layout</b>: ${this.exportData.layout.name}<br/>` +
      `<b>theme</b>: ${this.Theme?.name || 'default'}<br/>` +
      `<b>advertiser</b>: ${this.exportData.advertiser.name}<br/>` +
      `<b>tab</b>: ${this.exportData.tab}<br/>` +
      `<b>campaigns</b>: ${this.exportData.campaignIds}<br/>` +
      `<b>start date</b>: ${this.exportData.startDate}<br/>` +
      `<b>end date</b>: ${this.exportData.endDate}<br/>`;

    // if (this.exportData.summaryData?.Action?.Pacing) {
    //   this.debugMessage += `<b>pacing</b>: ${this.exportData.summaryData?.Action?.Pacing}<br/>`;
    // }
    // if (this.exportData.summaryData?.DISPLAY?.ClickThrough) {
    //   this.debugMessage += `<b>display clickthrough</b>: ${this.exportData.summaryData.DISPLAY.ClickThrough}<br/>`;
    // }
    // if (this.exportData.summaryData?.PREROLL?.ClickThrough) {
    //   this.debugMessage += `<b>preroll clickthrough</b>: ${this.exportData.summaryData.PREROLL.ClickThrough}<br/>`;
    // }
    // if (this.exportData.summaryData?.SOCIAL?.ClickThrough) {
    //   this.debugMessage += `<b>social clickthrough</b>: ${this.exportData.summaryData.SOCIAL.ClickThrough}<br/>`;
    // }
  },
});
