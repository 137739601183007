
import Vue from 'vue';
import { C360Header } from '@c360/ui';
import utils from '../../../util';

export default Vue.extend({
  name: 'adportal-export-header',
  inheritAttrs: false,
  components: { C360Header },
  props: ['sectionConfig', 'componentConfig', 'exportData', 'exportContext'],
  computed: {
    title(): string {
      return utils.htmlEncode(utils.renderTemplate(this.componentConfig.title, this.exportData));
    },
    subtitle(): string {
      return utils.htmlEncode(utils.renderTemplate(this.componentConfig.subtitle, this.exportData));
    },
    documentName(): string {
      return utils.htmlEncode(utils.renderTemplate(this.componentConfig.documentName, this.exportData));
    },
    documentType(): string {
      return utils.htmlEncode(utils.renderTemplate(this.componentConfig.documentType, this.exportData));
    },
    c360Link(): string {
      return process.env.VUE_APP_C360_URL || '';
    },
  },
  mounted() {
    setTimeout(() => {
      this.$emit('rendered', { empty: false });
    }, 200); // todo: properly wait for any image to load
  },
});
