
import Vue from 'vue';
import CompanyLogo from '../../global/companyLogo.vue';
import utils from '../../../util';

export default Vue.extend({
  name: 'export-header',
  inheritAttrs: false,
  components: { CompanyLogo },
  props: ['sectionConfig', 'componentConfig', 'exportData', 'exportContext'],
  computed: {
    toolbarTextColor(): string {
      return this.Theme.light.toolbarText;
    },
    toolbarColor(): string {
      return this.Theme.light.toolbarBackground;
    },
    whiteToolbar(): boolean {
      return this.Theme?.whiteToolbar;
    },
    title(): string {
      return utils.htmlEncode(utils.renderTemplate(this.componentConfig.title, this.exportData));
    },
    subtitle(): string {
      return utils.htmlEncode(utils.renderTemplate(this.componentConfig.subtitle, this.exportData));
    },
    documentName(): string {
      return utils.htmlEncode(utils.renderTemplate(this.componentConfig.documentName, this.exportData));
    },
    documentType(): string {
      return utils.htmlEncode(utils.renderTemplate(this.componentConfig.documentType, this.exportData));
    },
    colorToolbar(): boolean {
      return this.toolbarColor !== '#fff';
    },
    logoFontSize(): string {
      const longName = this.Theme?.companyName.length > 10;
      return longName ? 'body-1' : 'title';
    },
  },
  mounted() {
    setTimeout(() => {
      this.$emit('rendered', { empty: false });
    }, 200); // wait for logo image
  },
});
