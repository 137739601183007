
import Vue from 'vue';
import utils from '../../../util';

export default Vue.extend({
  inheritAttrs: false,
  name: 'export-footer',
  props: ['sectionConfig', 'componentConfig', 'exportData', 'exportContext'],
  computed: {
    buildInfo(): string {
      if (process && process.env && process.env.VUE_APP_BUILD_VERSION && process.env.VUE_APP_BUILD_VERSION.length > 8) {
        return `v.1.${process.env.VUE_APP_BUILD_VERSION.substr(3, 6)}`;
      }
      return 'n/a';
    },
    isSinclair(): boolean {
      return utils.isSinclair(this.Theme);
    },
  },
  mounted() {
    setTimeout(() => {
      this.$emit('rendered', { empty: false });
    }, 20);
  },
});
